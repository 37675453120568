<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Static Voters</div>
  </div>
  <div class="company-section-outer">
    <div class="layout-content-section">
      <div class="company-detail-box-outer">
        <div class="row g-2">
          <div
            class="
              col-lg-2 col-md-2 col-12
              voter-profile-static-col
              d-flex
              align-items-center
            "
          >
            <div class="customer-label-group">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column me-3">
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="far fa-star text-star-blank"></i>
                </div>
                <div>
                  <img
                    src="/assets/images/profile-4.jpg"
                    class="rounded bg-light me-auto d-block"
                    alt=""
                    width="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Name</label>
                  <div class="from-label-value">Mr. Suhas Vithal Desai</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Gendar | Status</label>
                  <div class="from-label-value">Male | Resident</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Id</label>
                  <div class="from-label-value">ZL01535483</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Date of Birth | Age</label>
                  <div class="from-label-value">20/05/1960 | 42</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area | Pin Code</label>
                  <div class="from-label-value">Nagala Park | 416012</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Voter Id)</label>
                  <div class="from-label-value">12</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Reg. Date | Reg. No</label>
                  <div class="from-label-value">20/07/1980 | MT08899</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Profession</label>
                  <div class="from-label-value">Salaried (Menon Pistons)</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Address)</label>
                  <div class="from-label-value">04</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Email</label>
                  <div class="from-label-value">suhasdesai@gmail.com</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div
                  class="
                    customer-label-group
                    mb-2
                    d-flex
                    justify-content-between
                  "
                >
                  <div>
                    <label class="form-label">Mobile</label>
                    <div class="from-label-value">+91 8887878888</div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button class="btn custom-outline-call-btn mx-2">
                      <i class="fas fa-phone-alt call-btn-color"></i></button
                    ><button class="btn custom-outline-whatsapp-btn me-2">
                      <i class="fab fa-whatsapp whatsapp-color"></i>
                    </button>
                    <!-- <button
                      @click="hierarchymodalopen()"
                      title="Family Hierarchy"
                      class="btn custom-outline-ellipsis-btn"
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="30"
                      />
                    </button> -->
                    <button
                      class="
                        total-voter-list
                        tongle-three-dot-btn
                        dropdown-toggle
                      "
                      id="dropdownMenupreference1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="22"
                        height="22"
                        alt=""
                      />
                    </button>
                    <ul
                      class="dropdown-menu total-voter-btn"
                      aria-labelledby="dropdownMenupreference1"
                    >
                      <li
                        class="list-group-item dropdown-list-custom"
                        @click="hierarchymodalopen"
                      >
                        Family Hierarchy
                      </li>
                      <li
                        class="list-group-item dropdown-list-custom"
                        @click="voterengagementmodalopen"
                      >
                        Engagements
                      </li>
                      <li
                        class="list-group-item dropdown-list-custom"
                        @click="votermaintenermodalopen"
                      >
                        Influencers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-detail-box-outer mt-3">
        <div class="row g-2">
          <div
            class="
              col-lg-2 col-md-2 col-12
              voter-profile-static-col
              d-flex
              align-items-center
            "
          >
            <div class="customer-label-group">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column me-3">
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="far fa-star text-star-blank mb-1"></i>
                  <i class="far fa-star text-star-blank mb-1"></i>
                  <i class="far fa-star text-star-blank"></i>
                </div>
                <div>
                  <img
                    src="/assets/images/profile-icon-3.svg"
                    class="rounded bg-light me-auto d-block"
                    alt=""
                    width="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Name</label>
                  <div class="from-label-value">Mr. Mohan Kisan Mane</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Gendar | Status</label>
                  <div class="from-label-value">Male | Non-Resident</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Id</label>
                  <div class="from-label-value">DJ01539920</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Date of Birth | Age</label>
                  <div class="from-label-value">03/08/1983 | 38</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area | Pin Code</label>
                  <div class="from-label-value">Baner | 411046</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Voter Id)</label>
                  <div class="from-label-value">09</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Reg. Date | Reg. No</label>
                  <div class="from-label-value">20/07/2001 | MT07283</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Profession</label>
                  <div class="from-label-value">Business (A1 Services)</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Address)</label>
                  <div class="from-label-value">07</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Email</label>
                  <div class="from-label-value">mohanmane9@gmail.com</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div
                  class="
                    customer-label-group
                    mb-2
                    d-flex
                    justify-content-between
                  "
                >
                  <div>
                    <label class="form-label">Mobile</label>
                    <div class="from-label-value">+91 9837487788</div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button class="btn custom-outline-call-btn mx-2">
                      <i class="fas fa-phone-alt call-btn-color"></i></button
                    ><button class="btn custom-outline-whatsapp-btn me-2">
                      <i class="fab fa-whatsapp whatsapp-color"></i>
                    </button>
                    <button
                      title="Family Hierarchy"
                      class="btn custom-outline-ellipsis-btn"
                      disabled
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="30"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company-detail-box-outer mt-3">
        <div class="row g-2">
          <div
            class="
              col-lg-2 col-md-2 col-12
              voter-profile-static-col
              d-flex
              align-items-center
            "
          >
            <div class="customer-label-group">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column me-3">
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="fas fa-star text-warning mb-1"></i>
                  <i class="far fa-star text-star-blank mb-1"></i>
                  <i class="far fa-star text-star-blank mb-1"></i>
                  <i class="far fa-star text-star-blank"></i>
                </div>
                <div>
                  <img
                    src="/assets/images/profile-icon-2.svg"
                    class="rounded bg-light me-auto d-block"
                    alt=""
                    width="90"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12 voter-name-static-col">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Name</label>
                  <div class="from-label-value">Mrs. Varsha Mohan Mane</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Gendar | Status</label>
                  <div class="from-label-value">Female | Resident</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Voter Id</label>
                  <div class="from-label-value">BB01567383</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Date of Birth | Age</label>
                  <div class="from-label-value">03/08/1991 | 32</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Area | Pin Code</label>
                  <div class="from-label-value">Baner | 411046</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Voter Id)</label>
                  <div class="from-label-value">14</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Reg. Date | Reg. No</label>
                  <div class="from-label-value">20/07/2008 | MT05284</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Profession</label>
                  <div class="from-label-value">House-wife</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Booth No (As Per Address)</label>
                  <div class="from-label-value">02</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 col-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-12">
                <div class="customer-label-group mb-2">
                  <label class="form-label">Email</label>
                  <div class="from-label-value">varsha1990@gmail.com</div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <div
                  class="
                    customer-label-group
                    mb-2
                    d-flex
                    justify-content-between
                  "
                >
                  <div>
                    <label class="form-label">Mobile</label>
                    <div class="from-label-value">+91 9983782212</div>
                  </div>
                  <div class="d-flex align-items-end">
                    <button class="btn custom-outline-call-btn mx-2">
                      <i class="fas fa-phone-alt call-btn-color"></i></button
                    ><button class="btn custom-outline-whatsapp-btn me-2">
                      <i class="fab fa-whatsapp whatsapp-color"></i>
                    </button>
                    <button
                      title="Family Hierarchy"
                      class="btn custom-outline-ellipsis-btn"
                      disabled
                    >
                      <img
                        src="/assets/images/icon/ellipsis-v-icon.svg"
                        width="30"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="hierarchymodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Family Hierarchy</h5>
          <button
            type="button"
            class="btn-close"
            @click="closerhierarchymodal()"
          ></button>
        </div>
        <OrganizationChart />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="engagementmodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Engagements</h5>
          <button
            type="button"
            class="btn-close"
            @click="closevoterengagementmodal()"
          ></button>
        </div>
        <EngagementTotalVoterModal />
      </div>
    </div>
  </div>
  <div class="modal-mask" v-if="maintenermodalstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Influencers</h5>
          <button
            type="button"
            class="btn-close"
            @click="closevotermaintenermodal()"
          ></button>
        </div>
        <MaintenerTotalVoterModal />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hierarchymodalstatus: false,
      engagementmodalstatus: false,
      maintenermodalstatus: false,
    };
  },
  methods: {
    hierarchymodalopen() {
      this.hierarchymodalstatus = true;
    },
    closerhierarchymodal() {
      this.hierarchymodalstatus = false;
    },
    voterengagementmodalopen() {
      this.engagementmodalstatus = true;
    },
    closevoterengagementmodal() {
      this.engagementmodalstatus = false;
    },
    votermaintenermodalopen() {
      this.maintenermodalstatus = true;
    },
    closevotermaintenermodal() {
      this.maintenermodalstatus = false;
    },
  },
};
</script>
<style scoped>
.company-header-box-outer {
  padding: 9px 16px;
  border: 1px solid #e0e5ed;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 0;
}
.company-legal-name {
  font-family: "AcuminPro-SemiBold";
  letter-spacing: 0px;
  color: #2b2e38;
  font-size: 16px;
  line-height: 20px;
  white-space: break-spaces;
}
.company-detail-box-outer {
  padding: 16px 16px 0;
  margin-bottom: 15px;
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer {
  border: 1px solid #e0e5ed;
  border-radius: 0px 0px 4px 4px;
}
.branch-detail-box-outer .branch-row-box {
  padding: 16px;
  border-bottom: 1px solid #e0e5ed;
}
.branch-detail-box-outer .branch-row-box:last-child {
  border-bottom: 0;
}
.customer-label-group {
  margin-bottom: 20px;
}
.form-label-header {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #2b2e38;
  line-height: 16px;
}
.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}
.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  height: 525px;
}
.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}
.custom-outline-whatsapp-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 5px 6px;
  line-height: 10px;
}
.custom-outline-whatsapp-btn .whatsapp-color {
  color: #2ca270;
  font-size: 18px;
}
.custom-outline-call-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}
.custom-outline-call-btn .call-btn-color {
  color: #2ca270;
  font-size: 13px;
}
.custom-outline-ellipsis-btn {
  padding: 0;
  border: 0;
}
.text-star-blank {
  color: #c1c5c9;
}
</style>